import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Header from './Header'
import Footer from './Footer'

import Symbols from './Symbols'

import Login from './Login/Login'
import Landing from './Login/Landing'
import API from './api/api'
import LoginSuccess from './Login/Success'
import config from './config'

import './App.scss'


const App = () => {
    //console.log("APP")
    //console.log(API.loggedIn())
    return (
        <>
           <BrowserRouter>
                <Header/>
                {(API.loggedIn()) ?
                <>
                <Routes>
                    <Route path={config.route.overview} element={<Symbols/>}/>
                    <Route path={config.route.login} element={<Symbols/>}/>
                    <Route path={config.route.symbol} element={<Symbols/>}/>
                    <Route path={config.route.base} element={<Symbols/>}/>
                    <Route path={config.route.landing} element={<Landing/>}/>
                    <Route path={config.route.loginsuccess} element={<LoginSuccess/>}/>
                </Routes>
                </> : <>
                <Routes>
                    <Route path={config.route.login} element={<Login/>}/>
                    <Route path={config.route.overview} element={<Login/>}/>
                    <Route path={config.route.base} element={<Login/>}/>
                    <Route path={config.route.landing} element={<Login/>}/>
                </Routes>
                </>}
                <Footer/>
            </BrowserRouter>
        </>

    )

}

export default App
