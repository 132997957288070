
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import config from "./config"
import API from './api/api'
import Router from './api/router'
import Select from 'react-select'


import {Helmet} from "react-helmet";
import {Container, Row, Col, Pagination} from 'react-bootstrap';
import TagList from './Components/TagList';
import TrustModal from './Components/TrustModal';

import countries from './media/countries.js';

import './Components/Status.scss'

const Symbols = () =>  {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)

    const STATUS_LIST  = 0
    const STATUS_MODAL = 1
    
    const [symbols, setSymbols]   = useState(null)
    const [current, setCurrent] = useState({})
    const [status, setStatus]   = useState(STATUS_LIST)
    const [selection, setSelection] = useState({
        symbol:  null,
        name:    null,
        sector:  null,
        country: null,
	tags:    null,
    })
    const [sorter,  setSorter] = useState({})
    const [pagination, setPagination] = useState({page_nr: 0, page_size: 25})
    const [errorMessage, setErrorMessage] = useState("")
    const [validTags,  setValidTags] = useState([])
    const [queryStatus, setQueryStatus] = useState({changed: true,
						    executing: false})

    useEffect(() => {
	getSymbols()
    }, [selection]);

    useEffect(() => {
	getSymbols()
    }, [pagination]);

    const country_list = countries.map((country) => {return {value: country, label: country}})
    useEffect(() => {
        const page_size = localStorage.getItem("page_size")
        setPagination({page_nr: 0, page_size: (page_size !== undefined && page_size !== null) ? page_size : 25})
	getValidTags()
    }, []);


    const onTagsSelected = (key, val) => {
	setPageNr(0)
        setSelection(prevState => ({...prevState, [key]: val.map((item) => {return item.value})}))
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
    }
    
    const onSelectionChange = (val) => {
	setPageNr(0)
        setSelection(prevState => ({...prevState, [val.target.name]: val.target.value}))
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
   }

    const setPageSize = (e) => {
	const new_page_size = parseInt(e.target.value)
	const new_page_nr   = parseInt(pagination.page_nr*pagination.page_size)/new_page_size
        setPagination({page_nr: new_page_nr, page_size: new_page_size})
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
        localStorage.setItem('page_size', new_page_size);
    }
	      
    const setPageNr = (page_nr) => {
        setPagination({page_nr: page_nr, page_size: pagination.page_size})
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
    }

    const openModal = (symbol) => {
        setCurrent(symbol)
    	setStatus(STATUS_MODAL)
    };

    const closeModal = (reload) => {
        setCurrent(current)
	setStatus(STATUS_LIST)
	if (reload) {
	    getSymbols(true)
	}
    };

    const getSymbols = async (force=false) => {
	if (!force && !queryStatus.changed) {
	    return
	}
	if (queryStatus.executing) {
	    return
	}
        setQueryStatus(prevState => ({...prevState, ["changed"]:  false, ["executing"]: true}))

	const query = {
	    page_nr:   pagination.page_nr,
	    page_size: pagination.page_size,
	    fuzzy: 1,
	}
	Object.keys(selection).map((key) => {
	    if (selection[key] !== null) {
	        query[key] = selection[key]
	    }
	})

	if (query.country !== undefined) {
	    query.country = query.country.join("|")
	}
	if (query.tags !== undefined) {
	    query.tags = query.tags.join("|")
	}
	console.log("QUERY")
	console.log(query)
	API.execute(router, config.api.symbol.list, query, setErrorMessage).then((result) => {
  	    setSymbols(result)
	    setQueryStatus(prevState => ({...prevState, ["executing"]: false}),
			   () => {
			       getSymbols()
			   })
	})
    }

    const getValidTags = async () => {
	const query = {
	    page_nr:   0,
	    page_size: 99999999999,
	}

	API.execute(router, config.api.tag.list, query, setErrorMessage).then((result) => {
	    if (result === null) {
	        setValidTags([])
    		return
	    }
  	    setValidTags(result.data.sort((a,b) => (a.tag_id > b.tag_id) ? -1 : (a.tag_id < b.tag_id) ? 1 : 0));
	})
    }

    const renderSearch = () => {
	const tags_list = validTags.filter((tag) => true).map((tag)=> {return {value: tag.tag_id, label: tag.tag_name}})
	
        return (
	        <>
            <Row className="symbol-content">
		<Col md="2">
		<div className="search-box">
		<input type="text" name="symbol" value={selection.symbol} onChange={onSelectionChange} placeholder="Symbol"/>
		</div>
		</Col>
		<Col md="4">
            	<div className="search-box">
		<input type="text" name="name" value={selection.name} onChange={onSelectionChange} placeholder="Organisation"/>
		</div>
		</Col>
		<Col md="2">
            	<div className="search-box">
		<input type="text" name="sector" value={selection.sector} onChange={onSelectionChange} placeholder="Sector"/>
		</div>
		</Col>
		<Col md="2">
            	<div className="search-box">
		    <Select options={country_list} isMulti value={selection.country}onChange={(val) => onTagsSelected("country", val)} name="country" placeholder="Country"/>
		</div>
		</Col>
            	<Col md="2">
		<div className="search-box">
		    <Select options={tags_list}    isMulti onChange={(val) => onTagsSelected("tags", val)}    name="tags"    className="search-tags-select"/>
		</div>
            	</Col>
	    </Row>
		</>
    )}



    
    const renderPagination = () => {
        if (symbols===undefined || symbols===null) {
            return (<></>)
        }
	const nr_pages = 3;
	const page_nr = pagination["page_nr"]
	const page_max = Math.ceil(symbols.count/pagination["page_size"])
	const pages = []
	for (let i=Math.max(0, page_nr-nr_pages); i<=Math.min(page_max-1, page_nr+nr_pages);i++) {
	    pages.push(i)
	}
	    
        return (<>
        <Row className="symbol-content">
	<Col md="4" className="symbol-content">
          <div className="contact-box">
	    <Pagination className="justify-content-center">
	      <Pagination.Prev disabled={page_nr===0} onClick={() => setPageNr(page_nr-1)} />
	      {pages.map((i)=>{return ( 
	        <Pagination.Item key={i+1} active={i === page_nr} onClick={() => setPageNr(i)}>{i+1}</Pagination.Item>
	      )})}
	      <Pagination.Next disabled={page_nr===page_max} onClick={() =>setPageNr(page_nr+1)} />
            </Pagination>
	  </div>
 	</Col>
	<Col md="4" className="symbol-content">
          <div className="contact-box">
              <label htmlFor="postsPerPageSelect" className="form-label">
                Symbols per page:
              </label>
              <select
                className="form-select"
                id="pageSize"
                value={pagination["page_size"]}
                onChange={setPageSize}
              >
                <option value={5}>5  </option>
                <option value={10}>10</option>
	        <option value={25}>25</option>
	        <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
              </select>
	  </div>
	</Col>
      </Row>
      </>
    )}

	
    const renderSymbols = () => {
        if (symbols===undefined || symbols===null) {
            return (<></>)
        }
        return (<>
		<Row className="symbol-content">
    		<Col md="2" className="symbol-content">
	          <div className="contact-box"><b>
		    Symbol
        	  </b></div>
        	</Col>
    		<Col md="4" className="symbol-content">
	          <div className="contact-box"><b>
		    Name
        	  </b></div>
        	</Col>
    		<Col md="2" className="symbol-content">
	          <div className="contact-box"><b>
		    Sector
        	  </b></div>
        	</Col>
    		<Col md="2" className="symbol-content">
	          <div className="contact-box"><b>
		    Country
        	  </b></div>
        	</Col>
    		<Col md="2" className="symbol-content">
	          <div className="contact-box"><b>
		    Tags
        	  </b></div>
        	</Col>
		</Row>
	    {symbols.data.map((symbol, index) => {
	        return (
		  <Row className="symbol-content">
       		    <Col className="symbol-content clickable" md={2}  onClick={(e)=>openModal(symbol)}>
	              <div className="contact-box">
		        {symbol.symbol}
     		      </div>
        	    </Col>
       		    <Col className="symbol-content clickable" md={4}  onClick={(e)=>openModal(symbol)}>
	              <div className="contact-box">
		        {symbol.name}
     		      </div>
        	    </Col>
       		    <Col className="symbol-content clickable" md={2}  onClick={(e)=>openModal(symbol)}>
	              <div className="contact-box">
		        {symbol.sector}
     		      </div>
        	    </Col>
       		    <Col className="symbol-content clickable" md={2}  onClick={(e)=>openModal(symbol)}>
	              <div className="contact-box">
		        {symbol.country}
     		      </div>
        	    </Col>
       		    <Col className="symbol-content clickable" md={2}  onClick={(e)=>openModal(symbol)}>
	              <div className="contact-box">
		        <TagList tags={symbol.tagged}/>
     		      </div>
        	    </Col>
                </Row>

        )})}
        </>
    )}



    return (
        <>
	    <Helmet>
	    <title>Symbols</title>
	    </Helmet>
            <div section className="block text-center contcts-box">
            <Container className="symbol-list">
	    {renderSearch()}
	    {renderSymbols()}
	    {renderPagination()}
            </Container>
            <div className="symbol-list">
	    {status === STATUS_MODAL ? <TrustModal
	     symbol={current}
	     closeModal={closeModal}
	     /> : <></>}
            </div>
        </div>
        </>
	)

}



export default Symbols



