import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import config from "../config"
import API from '../api/api';
import Router from '../api/router'
import GaugeChart from 'react-gauge-chart'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import TagList from './TagList';


const TrustModal = ({ symbol, closeModal }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    
    const [current, setCurrent]   = useState(symbol)
    const [articles, setArticles] = useState([])
    const [articleFilter, setArticleFilter] = useState({
	type: "",
    })
    const [trust, setTrust]       = useState({
	trust_score:        0.1,
	hype_score:         0.1,
	news_sensitivity:   0.1,
	trust_break:        0.1,
	trust_precision:    0.0,
	trust_sentiment:    0.0,
	trust_linguistics:  0.0,
	trust_exaggeration: 0.0,
	news_sentiment:     0.1,
	fraud_score:        0.0,
	expected_return:    0.0,
    })
    

    const STATUS_TRUST_OVERVIEW      = 1
    const STATUS_TRUST_DETAILS       = 2
    const STATUS_TRUST_SENSITIVITY   = 3
    const STATUS_ARTICLES_FULL       = 10
    const STATUS_ARTICLES_NEWS       = 11
    const STATUS_ARTICLES_PRESS      = 12
    const STATUS_ARTICLES_FINANCIALS = 13
    
    const [errorMessage, setErrorMessage] = useState("");
    const [changed, setChanged]           = useState({})
    const [status, setStatus]             = useState(STATUS_TRUST_OVERVIEW);
    const [strategies, setStrategies]     = useState(null);
    const [bias, setBias]                 = useState(null);
    const [ti, setTI]                 = useState(null);

    console.log("TrustModal", symbol, status)
     
    useEffect(() => {
	console.log("GETSYMBOL")
	getStrategies()
    	getTrust(symbol);
    }, []);

    useEffect(() => {
	console.log("GET Articles")
    	getArticles(symbol);
    	getBias(symbol);
    }, [status]);
    
    useEffect(() => {
	console.log("GET Bias")
    	getBias(symbol);
    }, [strategies]);

    const getTrust = async (symbol) => {
	if (symbol === null || symbol.symbol === undefined) {
	    return
	}
	const query = {
	    symbol:     symbol.symbol,
	}

	console.log("GetTrust - QUERY", query)
	let data = API.execute(router, config.api.trust.get, query, setErrorMessage).then((result) => {
	    console.log("GETTRUST", result)
	    if (result === null || result.data === undefined) {
		console.log("UNDEFINED SYMBOL")
		setTrust({
		    trust_score:      0.0,
		    news_sensitivity: 0.0,
		    news_sentiment:   0.0,
		    fraud_score:      0.0,
		    expected_return:  0.0,
		})
	    }
	    setChanged({})
  	    setTrust(result.data)
	})
    }
    
    const getBias = async (symbol) => {
	if (symbol === null || symbol.symbol === undefined) {
	    return
	}
	if (status !== STATUS_TRUST_OVERVIEW) {
	    return
	}
	console.log("GETBIAS", strategies)
	if (strategies===null) {
	    return
	}

	const query = {
	    symbol:     symbol.symbol,
	    strategy:   strategies.values[strategies.active],
	}

	console.log("GetBias - QUERY", query)
	let data = API.execute(router, config.api.stats.bias, query, setErrorMessage).then((result) => {
	    console.log("GETBIAS", result)
	    if (result === null || result.data === undefined) {
		setBias(null)
		setTI(null)
		return
	    }
	    console.log("TI1", result.ti)
	    console.log("TI2", result.ti.data)
	    console.log("TI3", JSON.parse(result.ti.data))
	    console.log("TI4", JSON.parse(JSON.parse(result.ti.data)))
   	    setBias(result)
   	    setTI(JSON.parse(JSON.parse(result.ti.data)))
	})
    }
    
    const getStrategies = async () => {
	if (strategies !== null) {
	    return
	}
	let data = API.execute(router, config.api.stats.strategies, {}, setErrorMessage).then((result) => {
	    if (result === null || result.data === undefined) {
		return
	    }
  	    setStrategies({'active': 0, 'values': result.data.map((strategy, idx)=> strategy)})
	})
    }
    
    const trust_gauge = (score, id) => {
	console.log("TrustGauge", id, score)
	console.log("Score", score)
	return (
		<GaugeChart id={id}
            nrOfLevels={20}
            percent={(score/2+0.5)}
            textColor="#9B9B9B"
            needleColor="#464646"
		/>
	);
    };

    const getArticles = async (symbol) => {
	if (symbol === null || symbol.symbol === undefined) {
	    return
	}
	const query = {
	    symbol:     symbol.symbol,
	}

	if (status === STATUS_ARTICLES_NEWS){
	    query.type = "news"
	}
	if (status === STATUS_ARTICLES_PRESS){
	    query.type = "press"
	}
	if (status === STATUS_ARTICLES_FINANCIALS){
	    query.type = "news"
	}
	
	console.log("GetArticles - QUERY", query)
	let data = API.execute(router, config.api.article.list, query, setErrorMessage).then((result) => {
	    console.log("GETARTICLES", result)
	    if (result === null || result.data === undefined) {
		console.log("UNDEFINED SYMBOL")
		setArticles([])
	    }
	    setChanged({})
  	    setArticles(result.data)
	})
    }

    const renderTrustOverview = () => {
	console.log("TrustOverview", trust)
	return (<>
	    <Row className="modal-row">
	      <Col md="6" className="modal-col">
	        <p>Trust</p>
	        <p>{trust_gauge(trust.trust_score, "gauge-trust-score")}</p>
	      </Col>
	      <Col md="6" className="modal-col">
	        <p>Hype</p>
	        <p>{trust_gauge(trust.hype_score, "gauge-trust-sentiment")}</p>
	      </Col>
	    </Row>
	    <Row>
	      <Col className="md-12">
	        {renderChart()}
	      </Col>
            </Row>
	    </>)
	   }

    
    const renderChart = () => {
        if (bias===undefined || bias==={} || bias===null) {
            return (<></>)
        }
	console.log("RENDERCHART", bias)

	ChartJS.register(LinearScale, PointElement, CategoryScale, LineElement, Title, Tooltip, Legend);
        const options = {
	    scales: {
		x: {beginAtZero: true,
		    display: true,
		    title: { 
			display: true, 
			align: 'center', 
			text: "Future Period",
			color: 'black',
		    },
		   },
		y: {beginAtZero: true,
		    display: true,
		    title: { 
			display: true, 
			align: 'center', 
			text: "Excess Return (%)",
			color: 'black',
		    },
		   },
	    },
	    options: {
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
	    }
	};
	    

	const data = {
	    labels: [0].concat(Object.values(bias.data).map((item)=>item.horizon)),
	    datasets: [{
		label: "Average",
		data: [0].concat(Object.values(bias.data).map((item)=>100*item.excessreturn)),
		fill: false,
		backgroundColor: "black",
		borderColor: "black",
	    },{
		label: "95% CI",
		data: [0].concat(Object.values(bias.data).map((item)=>100*item.ci95)),
		fill: false,
		backgroundColor: "gray",
		borderColor: "gray",
		borderDash: [5, 5],		
	    }, {
		label: "5% CI",
		data: [0].concat(Object.values(bias.data).map((item)=>100*item.ci05)),
		fill: false,
		backgroundColor: "gray",
		borderColor: "gray",
		borderDash: [5, 5],		
	    },
		      ]}
        console.log(data)
	
        return (<>
		<Row>
		<Col md="3">
		
		{(strategies !== null) ?
		 (<>
		  <select name="strategy" value={strategies.active} onChange={(e) => setStrategies((prevState) => ({...prevState, active: e.target.value}))} editable={true}>
		   {strategies.values.map((strategy, idx)=> (<option value={idx}>{strategy}</option>))}
		 </select>

		 </>)

		 : <></>}
		</Col>
		<Col md="3">
		{(bias!==null) ? "Last:\n"+bias.timestamp.substring(0,10) : ""}
		</Col>
		<Col md="3">
		{(bias!==null) ? "Bucket: "+bias.bucket : ""}
		</Col>
		<Col md="3">
		<TagList tags={current.tagged.filter((tag) => tag.tag_type==1)}/>
		</Col>
		</Row>
		<Row>
		<Col md="12">
		{(bias.data.length > 0) ? <Line options={options} data={data} /> : <></>}
		</Col>
		</Row>
		{(ti!==null) ? Object.keys(ti).sort().map((key)=> {
		    return (<>
			    <Row>
			    <Col md="6">{key}</Col><Col md="6">{ti[key]}</Col>
			    </Row>
			    </>)}

				     ) : <></>}
		</>);
    }

    
    const renderTrustDetails = () => {
	return (<>
            <Row className="modal-row">
	    <Col md="4" className="modal-col">
	    <p>Trust</p>
	    <p>{trust_gauge(trust.trust_score, "gauge-trust-score")}</p>
	    </Col>
	    <Col md="4" className="modal-col">
	    <p>Break in trust</p>
	    <p>{trust_gauge(trust.trust_break, "gauge-trust-score")}</p>
	    </Col>
	    <Col md="4" className="modal-col">
	    <p>Precision</p>
	    <p>{trust_gauge(trust.trust_precision, "gauge-trust-sentiment")}</p>
	    </Col>
	    </Row>
            <Row className="modal-row">
	    <Col md="4" className="modal-col">
	    <p>Sentiment</p>
	    <p>{trust_gauge(trust.trust_sentiment, "gauge-trust-sentiment")}</p>
	    </Col>
	    <Col md="4" className="modal-col">
	    <p>Linguistics</p>
	    <p>{trust_gauge(trust.trust_linguistics, "gauge-trust-score")}</p>
	    </Col>
	    <Col md="4" className="modal-col">
	    <p>Exaggeration</p>
	    <p>{trust_gauge(trust.trust_exaggeration, "gauge-trust-sentiment")}</p>
	    </Col>
	    </Row>
		</>)
    }

    const renderTrustSensitivity = () => {
	return (<>
	    <Row className="modal-row">
	      <Col md="6" className="modal-col">
	        <p>Trust</p>
	        <p>{trust_gauge(trust.trust_score, "gauge-trust-score")}</p>
	      </Col>
	      <Col md="6" className="modal-col">
	        <p>Hype</p>
	        <p>{trust_gauge(trust.hype_score, "gauge-trust-sentiment")}</p>
	      </Col>
	    </Row>
	    <Row className="modal-row">
	      <Col md="6" className="modal-col">
	        <p>News Sensitivity</p>
	        <p>{trust_gauge(trust.news_sensitivity, "gauge-new-sensitivity")}</p>
	      </Col>
	      <Col md="6" className="modal-col">
	        <p>Trend Break</p>
	        <p>{trust_gauge(trust.trust_break, "gauge-trend-break")}</p>
	      </Col>
	    </Row>
		</>)
    }

    
    const renderArticles = () => {
	return (<>{articles.map((article, index)=>{
	    console.log(article.title)
	    return(<>
  		   <Row onClick={() => window.open(article.url, '_blank').focus()}  className="modal-row">
		    <Col md="12" className="modal-col">
		    <p>-> {article.title}</p>
		    </Col>
	    </Row>
  	    <Row onClick={() => window.open(article.url, '_blank').focus()} className="modal-row">
		    <Col md="6" className="modal-col">
		    <p><small>{article.timestamp}</small></p>
		    </Col>
		    <Col md="2" className="modal-col">
		    <p><small>{article.type}</small></p>
		    </Col>
		    <Col md="2" className="modal-col">
		    <p><small>{article.source}</small></p>
		    </Col>
		    <Col md="2" className="modal-col">
		    <p><small>{article.author}</small></p>
		    </Col>
		   </Row>
		   </>)})}</>)
    }
    
    const renderTabs  = (view_articles) => {
	//const class_active = "modal-col clickable inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
	//const class_inactive = "modal-col clickable inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
	const class_active = "clickable inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
	const class_inactive = "clickable inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
	return (<>
            <Row className="modal-row">
		<Col md="3"
		className={(view_articles) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_TRUST_OVERVIEW)}
		>
		<p>Overview</p>
		</Col>
		<Col md="3"
		className={(status===STATUS_TRUST_DETAILS) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_TRUST_DETAILS)}
		>
		<p>Trust Details</p>
		</Col>
		<Col md="3"
		className={(status===STATUS_TRUST_SENSITIVITY) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_TRUST_SENSITIVITY)}
		>
		<p>Sensitivity</p>
		</Col>
		<Col md="3"
		className={(view_articles) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_ARTICLES_FULL)}
		>
		<p>Articles</p>
		</Col>
		</Row>
	    {(view_articles) ? <>
            <Row className="modal-row">
	     <Col md="3"
	     className={(status===STATUS_ARTICLES_FULL) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_ARTICLES_FULL)}
	     >
	     <p>Full</p>
	     </Col>
	     <Col md="3"
	     className={(status===STATUS_ARTICLES_PRESS) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_ARTICLES_PRESS)}
	     >
	     <p>Press Releases</p>
	     </Col>
	     <Col md="3"
	     className={(status===STATUS_ARTICLES_NEWS) ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"} 
		onClick={(event) => setStatus(STATUS_ARTICLES_NEWS)}
	     >
	     <p>News</p>
	     </Col>
	     <Col md="3"
	     className={(status===STATUS_ARTICLES_FINANCIALS)  ? "modal-col modal-tab-active clickable" : "modal-col modal-tab-inactive clickable"}
		onClick={(event) => setStatus(STATUS_ARTICLES_FINANCIALS)}
	     >
	     <p>Financials</p>
	     </Col>
	     </Row></> : <></>}
		</>)

    }
    
    const VIEW_ARTICLES = (status===STATUS_ARTICLES_FULL ||
			   status===STATUS_ARTICLES_NEWS ||
			   status===STATUS_ARTICLES_PRESS ||
			   status===STATUS_ARTICLES_FINANCIALS)

    console.log("TrustModal - status", status, VIEW_ARTICLES)
    return (
	<>
    	    <Modal
                isOpen={true}
                show={true}
        onHide={() => {
	    const has_changed = !(Object.values(changed).includes(true));
	    closeModal(has_changed);}}
                centered>
            <Modal.Header closeButton>
            <Modal.Title>{(current!==null) ? current.symbol : ""} - {(current !== null) ? current.name : ""}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-main">
            <Row className="modal-row">
	    <Col md="12" className="modal-col modal-error">
            {errorMessage}
    	    </Col>
	    </Row>
	    {renderTabs(VIEW_ARTICLES)}
            <Row className="modal-row">
	    <Col md="12" className="modal-col">
	    {(status===STATUS_TRUST_OVERVIEW)    ? renderTrustOverview() : <></>}
	    {(status===STATUS_TRUST_DETAILS)     ? renderTrustDetails() : <></>}
	    {(status===STATUS_TRUST_SENSITIVITY) ? renderTrustSensitivity() : <></>}
	    {(VIEW_ARTICLES) ? renderArticles() : <></>}
            </Col>
	    </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row><Col md="12">
                </Col></Row>
                </Modal.Footer>
            </Modal>
	    </>
        )
  	      

};

export default TrustModal;
