
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { SwitchHorizontalIcon, ScaleIcon, DatabaseIcon, UserCircleIcon,
} from '@heroicons/react/outline'
import API from './api/api'
import Router from './api/router'
import { IconDashboard, IconChat  } from './Icons'
import { useNavigate, useLocation } from "react-router-dom"

import Body from './Components/Body'
import config from "./config"
import Logo from './media/images/Logo.jpg'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const router   = new Router(navigate, location)
    const active   = router.is(config.route.base)
    console.log("Header - PATHS", location.pathname, config.route.base)

    const navClass = (route) => {
        return ((route === location.pathname) ? "header-item-active" : "navigation-menu")
        //return ((route === location.pathname) ? "header-item-active" : "header-item")
    }

    console.log("Header")

    useEffect(() => {
    }, {});

    
    const fromColor = () => {
	return "bg-red-200 text-red-600"
    }



    return (
    <>
    <Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
        <div className="mr-4">
        </div>
    </Body>
    <Navbar collapseOnSelect expand="lg" className="header border-b border-gray-300">
      <Container>
        <div className="mr-4">
            <NavLink to="/"><img src={Logo} width={96} alt={config.pageName}  className="brand-logo"/></NavLink>
        </div>
        <Navbar.Brand href={config.route.base}>Financial Trust</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        {(API.loggedIn()) ?
          <>
          <Nav>
              <Nav.Link href={config.route.overview} className={navClass(config.route.base)}>Overview</Nav.Link>
          </Nav>
          <Nav className="me-auto header">
            <NavDropdown title="Profile" id="collapsible-nav-profile" className="header">
              <NavDropdown.Item href={config.route.profile} className={navClass(config.route.base)}>Profile</NavDropdown.Item>
              <NavDropdown.Item href={config.route.logout} onClick={() => API.logout(router)} className={navClass(config.route.base)}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </> : <>
          <Nav>
              <Nav.Link href={config.route.overview} className={navClass(config.route.base)}>Overview</Nav.Link>
          </Nav>
          <Nav className="me-auto header">
            <Nav.Link href={config.route.login} className={navClass(config.route.login)}>Login</Nav.Link>
          </Nav>
            </>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Header
