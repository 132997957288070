const config = {
    pageName: "Financial Trust - Sikkema Investements",
    
    route: {
	url:           "https://trust.sikkemainvestments.com",
	    base:          "/",
	    overview:      "/overview",
	    landing:       "/landing",
	    login:         "/login",
	    loginsuccess:  "/loginsuccess",
	    profile:       "/profile",
	    reset:         "/reset",
	    resetSubmit:   "/resetSubmit",
	    details:       "/details",
	    symbols:       "/symbols",
    },
    api: {
    	url:       'https://api.sikkemainvestments.nl:6611/api/v01',
	login:      '/user/login',
	user:{
	    get: '/user/get',
	},
	symbol: {
	    list:     '/symbol/list',
	},
	article: {
	    list:     '/article/list',
	},
	trust: {
	    get:      '/trust/get',
	    list:     '/trust/list',
	},
	tag: {
	    get:      '/tag/get',
	    list:     '/tag/list',
	},
	stats: {
	    bias:       '/stats/bias',
	    strategies: '/stats/strategies',
	},
    },
};
  

export default config;


