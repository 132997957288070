import React, { useState } from 'react';
import './TagList.scss'

const TagList = ({ tags, handleTagInput, handleTagRemove, tagType, editable=false }) => {
  const [showTagInput, setShowTagInput] = useState(false);

  return (
    <div className="d-flex flex-wrap">
      {tags !== undefined &&
        Array.isArray(tags) &&
       tags.map((tag) => {
	   if ( tagType === null || tagType === undefined || tagType === tag.tag_type ) {
	       const class_name = (tag.tag_side===0||tag.tag_side===null) ? "custom-badge" : ((tag.tag_side < 0) ? "custom-badge-short" : "custom-badge-long")

	       return(
		       <p>
            <span
		   className={class_name}
              key={tag.tag_name}
              onClick={() => handleTagRemove(tag)}
            >
		       {tag.tag_name} &times;
            </span>
		       </p>)
           }})}

      {showTagInput ? (
        <input
          type="text"
          className="form-control w-auto"
          placeholder="Add tag..."
          onKeyDown={handleTagInput}
          onBlur={() => setShowTagInput(false)}
          autoFocus
        />
      ) : (
        <p>
          <span
            className="addTag"
            onClick={() => setShowTagInput(true)}
            style={{ cursor: 'pointer' }}
          >
              {editable ? "+" : ""}
          </span>
        </p>
      )}
    </div>
  );
};

export default TagList;
